<style scoped></style>

<template>
  <router-view class="config-page"/>
</template>

<script>
export default {

}
</script>
